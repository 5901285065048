import React, { useEffect } from "react";
import { graphql } from "gatsby";

import { motion } from "framer-motion";
import HTMLReactParser from "html-react-parser";

import { DefaultContainer, DotContainer } from "components/common/Structure";
import CreateHead from "components/createHeader";
import Footer from "components/footer";
import Colored from "components/colored/Colored";

const WhatWeDo = (props) => {
  //PROPS
  const {
    data: {
      page: {
        whatwedo: {
          bTitle,
          bParagraph,
          fixedColumn: { fTitle, fText },
          serviceColumn,
        },
      },
    },
    setColor,
  } = props;
  //PROPS

  //DEFS
  const lang =
    props.pageContext.translations[0].language.code === "EN" ? "PT" : "EN";
  //DEFS

  //PAGEANIMS
  const pageAnims = {
    hidden: {
      opacity: 0,
      y: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: 0, duration: 1 },
    },
    exit: {
      opacity: 0,
      y: 0,
      transition: { ease: "easeInOut", duration: 1 },
    },
  };
  //PAGEANIMS

  useEffect(() => {
    setColor(true);
  }, []);

  return (
    <>
      <motion.div
        variants={pageAnims}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <DefaultContainer>
          <section className="container container--xsmall">
            <div className="column fixed">
              {fTitle && HTMLReactParser(fTitle)}
              {fText && HTMLReactParser(fText)}
            </div>
            <div className="column circle-box-container">
              {serviceColumn.map((row, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="circle-cta">
                      <DotContainer color={row.sColor} />
                    </div>
                    <div className="text-block">
                      <h3>{row.sTitle}</h3>
                      {row.sText && HTMLReactParser(row.sText)}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
          <Colored ctitle={bTitle} cinfo={bParagraph} />
        </DefaultContainer>
        <Footer lang={lang} />
      </motion.div>
    </>
  );
};

export const query = graphql`
  query WhatWeDo($id: String) {
    page: wpPage(id: { eq: $id }) {
      whatwedo {
        bTitle
        bParagraph {
          bText
        }
        fixedColumn {
          fTitle
          fText
        }
        serviceColumn {
          sColor
          sText
          sTitle
        }
      }
    }
  }
`;

export default WhatWeDo;
