import styled from "styled-components";

import { h2 } from "theme/typography";
import { ColorAnim } from "theme/animations";

export const ColoredContainer = styled.section`
  padding: 50px 30px;
  ${ColorAnim};
  .container {
    .h2,
    a {
      ${h2};
      width: 100%;
    }
    a {
      text-decoration: underline;
    }
    strong {
      font-weight: bold;
    }
    .colored-text {
      margin-top: 30px;
      p {
        margin: 0;
      }
    }
  }
  @media (min-width: 769px) {
    padding-top: 150px;
    padding-bottom: 150px;
    .container {
      .h2,
      a {
        width: 50%;
      }
    }
  }
`;
